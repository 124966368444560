@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hubballi&display=swap");
* {
  margin: 0;
  padding: 0;
}
:root {
  --primary-color: #274045;
  --secondary-color: rgba(255, 255, 255, 0.879);
  --secondary-dark-color: rgb(255, 255, 255, 0.4);
  --alternative-blue: #388b9c;
  --alternative-blue-dark-color: #16505c;
  --white-dull: rgb(255, 255, 255, 0.22);
  --white-dark-color: rgb(255, 255, 255, 0.4);
  --glow-bg-color: rgba(255, 255, 255, 0.72);
  --vignette: rgb(0, 0, 0, 0.7);
  --glow-color: rgba(255, 255, 255, 0.369);
}

html,
body {
  overflow: hidden;
  height: 100%;
  width: 100%;
  transition: all 3s;
  display: inline-flex;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: -1;
}

.lp {
  position: fixed;
  height: 100%;
  width: 100%;
  justify-content: center;
  display: none;
  z-index: 2;
  flex-direction: column;
  align-items: center;
}

.lp-s {
  margin-top: auto;
  height: 1px;
  width: 30%;
  opacity: 1;
  margin-bottom: 30px;
}
.lp-b {
  height: 0px;
  background-color: var(--secondary-color);
  width: 0;
}
.lp-quote {
  font-size: 18px;
  color: var(--secondary-color);
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  opacity: 1;
  font-style: italic;
  margin-bottom: 50px;
  opacity: 0;
}

.lpl-i {
  opacity: 1;
  width: 45%;
  height: auto;
}
.lpl-d {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  height: 100%;
}

.lo {
  height: 50%;
  width: auto;
  margin-top: auto;
}

.door-d {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}
.door-f {
  width: 10%;
  opacity: 0;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  border-top: 0.25px solid var(--secondary-color);
  border-bottom: 0.25px solid var(--secondary-color);
}
.doord-l {
  background-color: var(--primary-color);
  height: inherit;
  width: inherit;
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.75) inset;
  clip-path: inset(0px 0px 30px 0px);
}
.doord-r {
  background-color: var(--primary-color);
  height: inherit;
  width: inherit;
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.75) inset;
  clip-path: inset(30px 0px 0px 0px);
}

.audio-c {
  display: none;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: var(--white-dull);
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  margin-top: 100px;
  letter-spacing: 1px;
  font-size: 16px;
}
.audio-choice {
  display: flex;
  flex-direction: row;
  margin: 10px;
  z-index: 10001;
  cursor: pointer;
  transition: all 1s;
}

.audio-choice:hover {
  color: var(--secondary-color);
}

.nav-curtain {
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: row;
  z-index: 3;
  position: absolute;
}

.nav-left {
  width: 0%;
  height: 100%;
  background-color: var(--primary-color);
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
}

.data {
  opacity: 0;
  padding: 30px;
  font-size: 16px;
  color: var(--secondary-color);
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  height: 70%;
}

.package {
  font-size: 18px;
}
.data-name {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: var(--secondary-color);
  letter-spacing: 1px;
  margin: 5px;
}
.hctag {
  font-size: 14px;
}
.data-tag {
  font-size: 16px;
  color: var(--secondary-color);
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  margin: 5px;
}
.data-content {
  font-size: 14px;
  color: var(--secondary-color);
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  margin: 5px;
}
.article {
  text-decoration: none;
  color: white;
  text-decoration: underline;
}
.nav-right {
  width: 0%;
  height: 100%;
  background-color: var(--primary-color);
  margin-left: auto;
  display: flex;
  flex-direction: row;
}

.aboutimage {
  opacity: 0;
  display: none;
  justify-content: center;
  align-items: center;
}

.aboutusimg {
  height: 70%;
  width: auto;
}
.close-button {
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  margin: 40px;
  color: var(--white-dull);
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
}

.close-button:hover {
  color: var(--secondary-color);
}

.review-container {
  height: 90%;
  width: 90%;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.review-card {
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  display: flex;
  height: 280px;
  width: 200px;
  background-color: #182629;
  border-radius: 10px;
  box-shadow: -1rem 0 3rem #000;
  transition: 0.4s ease-out;
  position: relative;
  left: 0px;
}

.review-card:not(:first-child) {
  margin-left: -50px;
}

.review-card:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.review-card:hover ~ .review-card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.review-title {
  color: white;
  font-weight: 300;
  position: absolute;
  left: 20px;
  top: 15px;
}

.review-bar {
  position: absolute;
  top: 100px;
  left: 20px;
  height: 5px;
  width: 150px;
}
.review-text {
  color: var(--secondary-color);
  margin: 10px;
  position: absolute;
  font-size: 14px;
  bottom: 10px;
  text-align: justify;
}
.review-emptybar {
  background-color: #2e3033;
  width: 100%;
  height: 100%;
}

.review-filledbar {
  position: absolute;
  top: 0px;
  z-index: 3;
  width: 0px;
  height: 100%;
  background: rgb(255, 255, 255);
  transition: 0.6s ease-out;
}

.review-card:hover .review-filledbar {
  width: 150px;
  transition: 0.4s ease-out;
}

.review-stroke {
  stroke: white;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
  transition: 0.6s ease-out;
}

/** MOBILE PORTRAIT **/
@media screen and (max-width: 480px) {
  .audio-c {
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0;
    color: var(--secondary-color);
    font-family: "Oswald", sans-serif;
    font-weight: 200;
    margin-top: 60px;
    letter-spacing: 1px;
    font-size: 14px;
  }
  .door-f {
    width: 30%;
    opacity: 0;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    border-top: 0.25px solid var(--secondary-color);
    border-bottom: 0.25px solid var(--secondary-color);
  }

  .nav-left {
    width: 0%;
    height: 100%;
    background-color: var(--primary-color);
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .data {
    opacity: 0;
    padding: 5px;
    font-size: 12px;
    color: var(--secondary-color);
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    height: 70%;
  }
  .data-name {
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: var(--secondary-color);
    letter-spacing: 1px;
    margin-left: 5px;
  }
  .hctag {
    font-size: 12px;
  }
  .data-tag {
    font-size: 12px;
    color: var(--secondary-color);
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    margin-left: 5px;
  }
  .data-content {
    font-size: 12px;
    color: var(--secondary-color);
    font-family: "Montserrat", sans-serif;
    font-weight: 200;
    margin: 5px;
  }
  .close-button {
    font-family: "Oswald", sans-serif;
    font-weight: 200;
    color: var(--white-dull);
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .aboutusimg {
    height: auto;
    width: 95%;
  }
}

/** MOBILE LANDSCAPE **/
@media screen and (max-width: 850px) and (orientation: landscape) {
  .audio-c {
    display: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0;
    color: var(--secondary-color);
    font-family: "Oswald", sans-serif;
    font-weight: 200;
    margin-top: 50px;
    letter-spacing: 1px;
    font-size: 14px;
  }
}
/** TABLET PORTRAIT **/
@media screen and (max-width: 1024px) and (orientation: portrait) {
  .aboutusimg {
    height: auto;
    width: 95%;
  }
}
