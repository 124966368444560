.twoDe {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}

.ap-m {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.am-s {
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  font-size: 16px;
  color: var(--secondary-color);
}

.am-bar {
  height: 1px;
  width: 100%;
  background-color: var(--secondary-color);
}

.ll {
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  margin: 1px;
}

.ll-d {
  display: none;
  opacity: 0;
  flex-direction: row;
  top: 50px;
  left: 100px;
  position: absolute;
  z-index: 4;
}

.sense-logo {
  width: 36px;
  height: 36px;
  margin-left: 10px;
}
.powered {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(--secondary-color);
  z-index: 10;
}

/** MOBILE PORTRAIT **/
@media screen and (max-width: 480px) {
  .am-s {
    font-family: "Oswald", sans-serif;
    font-weight: 200;
    font-size: 14px;
    color: var(--secondary-color);
  }

  .ll-d {
    display: none;
    opacity: 0;
    flex-direction: row;
    top: 20px;
    left: 40px;
    position: absolute;
  }

  .ll {
    color: #ffffff;
    font-family: "Oswald", sans-serif;
    font-weight: 200;
    margin: 1px;
    font-size: 16px;
  }
}

/** MOBILE LANDSCAPE **/
@media screen and (max-width: 850px) and (orientation: landscape) {
  .am-s {
    font-family: "Oswald", sans-serif;
    font-weight: 200;
    font-size: 14px;
    color: var(--secondary-color);
  }

  .ll-d {
    display: none;
    opacity: 0;
    flex-direction: row;
    top: 20px;
    left: 20px;
    position: absolute;
    font-size: 14px;
  }
}

/** TABLET PORTRAIT **/
@media screen and (max-width: 1024px) and (orientation: portrait) {
  .ll-d {
    display: none;
    opacity: 0;
    flex-direction: row;
    top: 20px;
    left: 40px;
    position: absolute;
    font-size: 14px;
  }
}
