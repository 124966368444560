.ch-d {
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.ch {
  height: 100%;
  width: 100%;
}
.ch-dum {
  display: flex;
  flex-direction: column-reverse;
}
.nav-b {
  height: auto;
  width: 6%;
  margin-bottom: 50px;
  margin-left: 100px;
  opacity: 0.5;
  z-index: 3;
  cursor: pointer;
}

.nav-options {
  color: var(--secondary-color);
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  font-size: 14px;
  display: none;
  opacity: 0;
  flex-direction: column;
  margin-left: 100px;
  z-index: 3;
  width: fit-content;
}
.nav-o-b {
  margin-bottom: 9px;
  letter-spacing: 1px;
  cursor: pointer;
}

.nav-o-blog {
  text-decoration: none;
  color: var(--secondary-color);
}

.wtlink {
  color: var(--secondary-color);
  opacity: 0;
  display: none;
}

.wtlink a {
  text-decoration: none;
  color: var(--secondary-color);
  margin-left: 7px;
}

.smlink {
  color: var(--secondary-color);
  display: flex;
  flex-direction: row;
  opacity: 0;
  display: none;
}

.smlink a {
  text-decoration: none;
  color: var(--secondary-color);
  margin-left: 7px;
}

.ch-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--secondary-color);
  font-family: "Oswald", sans-serif;
  opacity: 0;
  z-index: 1;
}
.ch-content {
  width: 36%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
}
.ch-header {
  font-size: 27px;
  font-weight: 500;
  text-align: left;
  width: 100%;
}

.nutrition {
  font-size: 27px;
  font-weight: 500;
  text-align: left;
  width: 100%;
  text-decoration: none;
  color: var(--secondary-color);
}

.ch-line {
  border-bottom: 0.1em solid var(--secondary-color);
  width: 100%;
  margin-bottom: 10px;
}

.ch-body {
  font-size: 18px;
  font-weight: 200;
  font-style: italic;
  text-align: justify;
  width: 100%;
  letter-spacing: 1px;
}

.ch-next {
  width: 36%;
  padding: 5px;
  text-align: end;
  margin-top: 20px;
  opacity: 0;
}

.ch-button {
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding: 20px 25px;
  margin: 20px;
  color: var(--secondary-color);
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  border-radius: 2px;
}
.ch-button:nth-child(1) {
  filter: hue-rotate(270deg);
}
.ch-button:nth-child(2) {
  filter: hue-rotate(110deg);
}
.ch-button span {
  position: absolute;
  display: block;
}
.ch-button span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--secondary-color));
  animation: animate1 2s linear infinite;
}
@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}
.ch-button span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, var(--secondary-color));
  animation: animate2 2s linear infinite;
  animation-delay: 0.5s;
}
@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 3s cubic-bezier(0.215, 0.61, 0.355, 1)
    3s both;
  animation: tracking-in-expand 3s cubic-bezier(0.215, 0.61, 0.355, 1) 3s both;
}

.scale-in-ver-top {
  -webkit-animation: scale-in-ver-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s
    both;
  animation: scale-in-ver-top 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s both;
}

.puff-in-center {
  -webkit-animation: puff-in-center 3s cubic-bezier(0.47, 0, 0.745, 0.715) 9s
    both;
  animation: puff-in-center 3s cubic-bezier(0.47, 0, 0.745, 0.715) 9s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-2-1 14:39:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

/** MOBILE PORTRAIT **/
@media screen and (max-width: 480px) {
  .ch-header {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    width: 100%;
  }

  .ch-body {
    font-size: 12px;
    font-weight: 200;
    font-style: italic;
    text-align: justify;
    width: 100%;
    letter-spacing: 1px;
  }
  .ch-button {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 10px 15px;
    margin: 5px;
    color: var(--secondary-color);
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    letter-spacing: 2px;
    overflow: hidden;
    font-size: 14px;
  }
  .nav-b {
    height: 40px !important;
    width: auto !important ;
    opacity: 0.5;
    z-index: 3;
    cursor: pointer;
    position: absolute;
    top: 40px;
    right: 40px;
  }
  .nav-options {
    color: var(--secondary-color);
    font-family: "Oswald", sans-serif;
    font-weight: 200;
    font-size: 12px;
    display: none;
    opacity: 0;
    flex-direction: column;
    margin-left: 10px;
    z-index: 3;
    width: fit-content;
    position: absolute;
    top: 90px;
    right: 40px;
  }
  .nav-o-b {
    margin-bottom: 3px;
    letter-spacing: 1px;
    cursor: pointer;
  }
}
/** MOBILE LANDSCAPE **/
@media screen and (max-width: 850px) and (orientation: landscape) {
}

/** TABLET PORTRAIT **/
@media screen and (max-width: 1024px) and (orientation: portrait) {
  .ch-content {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
  }
  .ch-next {
    width: 63%;
    padding: 5px;
    text-align: end;
    margin-top: 20px;
  }
  .nav-b {
    height: auto;
    width: 21%;
    margin-bottom: 40px;
    margin-left: 40px;
    opacity: 0.5;
    z-index: 3;
    cursor: pointer;
  }
  .nav-options {
    color: var(--secondary-color);
    font-family: "Oswald", sans-serif;
    font-weight: 200;
    font-size: 12px;
    display: none;
    opacity: 0;
    flex-direction: column;
    margin-left: 20px;
    z-index: 3;
    width: fit-content;
  }
  .nav-o-b {
    margin-bottom: 5px;
    letter-spacing: 1px;
    cursor: pointer;
  }
}

@media (hover: hover) {
  .ch-button:hover {
    background: var(--glow-bg-color);
    color: var(--primary-color);
    box-shadow: 0 0 5px var(--glow-color), 0 0 25px var(--glow-color),
      0 0 50px var(--glow-color), 0 0 200px var(--glow-color);
    -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }
}
